/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import NukaCarousel from 'nuka-carousel';
import BaseSection from './BaseSection';
import PhotoGallery from '../components/PhotoGallery';
import Images from '../utils/Images';
import { withEdit } from '../Context';
import './carousel-section.scss';

const IMAGE_SIZE = 1200;
const LAZY_IMAGE_SIZE = 120;

const autoplayInterval = {
  slow: 10000,
  medium: 5000,
  fast: 3000,
};

const CarouselButton = ({ children, onClick }) => (
  <button type="button" className="carousel__button" onClick={onClick}>
    {children}
  </button>
);

CarouselButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CarouselThumbnails = ({ images, currentSlide, goToSlide }) => {
  const size = images.length <= 10 ? 50 : 35;
  return (
    <div className="carousel__thumbnails">
      {images.map((image, index) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          key={image.id}
          className={cx('carousel__thumbnail', { active: index === currentSlide })}
          src={Images.square(image, size)}
          onClick={() => goToSlide(index)}
          style={{ width: size, height: size }}
        />
      ))}
    </div>
  );
};

CarouselThumbnails.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

class Carousel extends React.PureComponent {
  state = {
    maxShownSlide: 0,
  };

  handleSlideChange = (_currentSlide, endSlide) => {
    this.setState((state) => ({ maxShownSlide: Math.max(state.maxShownSlide, endSlide) }));
  };

  getBottomControlsRenderer = (controls) => {
    const { images } = this.props;
    switch (controls) {
      case 'none':
        return () => null; // Empty component
      case 'thumbnails':
        return (props) => <CarouselThumbnails images={images} {...props} />;
      case 'dots':
      default:
        // Default carousel controls
        return undefined;
    }
  };

  render() {
    const { images, showArrows, bottomControls, ...carouselProps } = this.props;
    const { maxShownSlide } = this.state;

    return (
      <NukaCarousel
        {...carouselProps}
        beforeSlide={this.handleSlideChange}
        renderBottomCenterControls={this.getBottomControlsRenderer(bottomControls)}
        // renderBottomCenterControls={showDots ? undefined : () => null}
        renderCenterLeftControls={({ currentSlide, previousSlide }) => {
          return showArrows && currentSlide !== 0 ? (
            <CarouselButton onClick={previousSlide}>‹</CarouselButton>
          ) : undefined;
        }}
        renderCenterRightControls={({ currentSlide, nextSlide }) => {
          return showArrows && currentSlide !== images.length - 1 ? (
            <CarouselButton onClick={nextSlide}>›</CarouselButton>
          ) : undefined;
        }}
      >
        {images.map((image, index) => {
          const isLazyLoaded = index > maxShownSlide + 2;
          // Lazy loading will preload a thumbnail, just in case it scrolls too fast.
          const imgSize = isLazyLoaded ? LAZY_IMAGE_SIZE : IMAGE_SIZE;
          // eslint-disable-next-line jsx-a11y/alt-text
          return (
            <img
              alt="carousel"
              key={image.id || index}
              src={Images.maxWidth(image, imgSize)}
              style={{ height: 500, objectFit: 'contain' }}
            />
          );
        })}
      </NukaCarousel>
    );
  }
}

Carousel.defaultProps = {
  bottomControls: 'dots',
  showArrows: false,
};

Carousel.propTypes = {
  bottomControls: PropTypes.oneOf(['none', 'dots', 'thumbnails']),
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  showArrows: PropTypes.bool,
};

class CarouselSection extends PureComponent {
  render() {
    const { itemList, margin, isEditing, autoplay, showArrows, bottomControls, ...props } =
      this.props;
    return (
      <BaseSection {...props} block={this.props}>
        <Carousel
          className="carousel"
          images={itemList.items.map((item) => item.image).filter((v) => v)}
          showArrows={showArrows}
          bottomControls={bottomControls}
          autoplay={autoplay && autoplay !== 'none'}
          autoplayInterval={autoplayInterval[autoplay]}
        />
        {isEditing && (
          <div>
            <h2 style={{ marginBottom: 16 }}>Images à afficher</h2>
            <PhotoGallery images={itemList.items} gridSize={6} showLabels={false} margin={margin} />
          </div>
        )}
      </BaseSection>
    );
  }
}

CarouselSection.defaultProps = {
  autoplay: 'none',
  bottomControls: 'dots',
  isEditing: false,
  itemList: { items: [] },
  margin: 'normal',
  showArrows: true,
};

CarouselSection.propTypes = {
  autoplay: PropTypes.oneOf(['none', 'slow', 'medium', 'fast']),
  bottomControls: PropTypes.oneOf(['none', 'dots', 'thumbnails']),
  isEditing: PropTypes.bool,
  itemList: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object) }),
  margin: PropTypes.oneOf(['none', 'normal', 'large']),
  showArrows: PropTypes.bool,
};

export default withEdit(CarouselSection);
