/* eslint-disable no-restricted-globals */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Div, Img } from 'react-images/lib/primitives';
import { className } from 'react-images/lib/utils';
import { getSource } from 'react-images/lib/components/component-helpers';

// Can use here, AlbumModal isn't present in SSR code
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

class HeaderButton extends PureComponent {
  render() {
    const { icon, onClick, href } = this.props;
    if (href) {
      return (
        <a
          className="fullscreen__header__button"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          download
          aria-label="Download image"
        >
          <i className={icon} />
        </a>
      );
    }
    return (
      <div className="fullscreen__header__button" onClick={onClick}>
        <i className={icon} />
      </div>
    );
  }
}

HeaderButton.defaultProps = {
  href: undefined,
  onClick: undefined,
};

HeaderButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

class Header extends PureComponent {
  render() {
    // const { components, getStyles, getCloseLabel, getFullscreenLabel, innerProps } = this.props;
    const { isModal, modalProps, currentView, interactionIsIdle } = this.props;

    if (!isModal) return null;

    const { allowFullscreen, isFullscreen, onClose, toggleFullscreen } = modalProps;
    return (
      <div className={`fullscreen__header ${interactionIsIdle ? '' : 'visible'}`}>
        <div style={{ flex: 1 }} />
        <HeaderButton icon="fas fa-file-download" href={currentView.cdn} />
        {allowFullscreen && (
          <HeaderButton
            icon={isFullscreen ? 'fas fa-compress' : 'fas fa-expand'}
            onClick={toggleFullscreen}
          />
        )}
        <HeaderButton icon="fas fa-times" onClick={onClose} />
      </div>
    );
  }
}

Header.defaultProps = {
  isModal: undefined,
  modalProps: {},
  interactionIsIdle: undefined,
};

Header.propTypes = {
  isModal: PropTypes.bool,
  modalProps: PropTypes.object,
  currentView: PropTypes.shape({
    cdn: PropTypes.string.isRequired,
  }).isRequired,
  interactionIsIdle: PropTypes.bool,
};

function svgPlaceholder(width, height) {
  // eslint-disable-next-line max-len
  return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
}

export function CustomView(props) {
  // eslint-disable-next-line react/prop-types
  const { data, formatters, getStyles, index, currentIndex, isFullscreen, isModal } = props;
  const overscan = 3;
  const inBounds = Math.abs(currentIndex - index) <= overscan;

  const innerProps = {
    // eslint-disable-next-line react/prop-types
    alt: formatters.getAltText({ data, index }),
    src: inBounds ? getSource({ data, isFullscreen }) : svgPlaceholder(data.width, data.height),
  };

  return (
    <Div css={getStyles('view', props)} className={className('view', { isFullscreen, isModal })}>
      <Img
        {...innerProps}
        className={className('view-image', { isFullscreen, isModal })}
        css={{
          height: 'auto',
          maxHeight: '100vh',
          maxWidth: '100vw',
          userSelect: 'none',
        }}
      />
    </Div>
  );
}

CustomView.propTypes = {
  data: PropTypes.object.isRequired,
};

class AlbumModal extends PureComponent {
  renderModal() {
    const { currentImage, photos, onClose } = this.props;

    const views = photos.map((x) => ({
      ...x,
      source: x.screenSrc,
    }));

    // Force single image in IE11
    const useSingleImage = isIE;

    // Lock on 1 photo...
    if (useSingleImage) {
      return (
        <Modal onClose={onClose}>
          <Carousel currentIndex={0} components={{ Header }} views={[views[currentImage]]} />
        </Modal>
      );
    }

    return (
      <Modal onClose={onClose}>
        <Carousel
          currentIndex={currentImage}
          // components={{ Header }}
          components={{ Header, View: CustomView }}
          views={views}
          on
        />
      </Modal>
    );
  }

  render() {
    return <ModalGateway>{this.renderModal()}</ModalGateway>;
  }
}

AlbumModal.defaultProps = {
  currentImage: undefined,
};

AlbumModal.propTypes = {
  currentImage: PropTypes.number,
  photos: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlbumModal;
